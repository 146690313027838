import './App.css';
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Tokenomics from "./components/Pages/Tokenomics";
import Roadmap from "./components/Pages/Roadmap";
import Community from "./components/Pages/Community";
import Chat from "./components/Pages/Chat";

import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getData } from './store/appStoreSlice';
import { setStore } from "./utils/utils";

import { ToastContainer, toast } from 'react-toastify';
import { ch,en,ru } from './utils/lang';


import 'react-toastify/dist/ReactToastify.css';



const useStyles = makeStyles((theme) => ({
  blur: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backdropFilter: "blur(3px)",
  },
  hidden: {
    display: "none",
  },
  app: {

    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gridTemplateRows: "42px 1fr 62px",
  },
  panelbox: {
    width: "auto",
    margin: "auto",
  },
}));

const App = (props) => {
  const classes= useStyles();
  const history = useHistory();
  const location = useLocation();
  const { modal, locale,  darkTheme } = useSelector(getData);
  

  
  useEffect(() => {
    if (locale == 'en') {
      setStore({translator: en});
    }
    if (locale == 'ch') {
      setStore({translator: ch});
    }
    if (locale == 'ru') {
      setStore({translator: ru});
    }
      

  }, [locale]);
  
  return (
<>
  <ToastContainer theme={darkTheme ? "dark" : "light"} />
      <div className={classes.app} id="root">
        <Route  path="/" exact render={() =>
          <>

            <Home />
          </>
        } />

        

        <div className={`${classes.blur} ${!modal ? classes.hidden : ''}`}/>

      </div>
</>
  );
}

export default App;
